export const environment = {
  production: true,
  baseUrl: 'https://testapi-ecomodule.procredit-group.com',
  appInsights: {
    instrumentationKey: '325d3373-a212-4933-bcf3-2623b1dd5c03',
  },
  msalConfig: {
    uri: 'https://graph.microsoft.com/v1.0/me',
    authority: 'https://login.microsoftonline.com/',
  },
  languageBarVisibility: false,
  isImportEnabled: true,
  isFileUploadEnabled: true,
  enablePB: false,
  maxNumber: 1_000_000_000_000_000,
};
